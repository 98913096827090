.subheadline {
  font-size: .7rem;
  text-transform: uppercase;
  font-weight: 800;
  letter-spacing: 2px;
  color: #e19e67;
}

.headline {
  font-size: 2.5rem;
  line-height: 1.4;
  color: #34222e;
}