.certificate.card {
  text-align: center;
}

.certificate .card-img-bottom {
  padding: 2rem;
}

.gradient {
  background: rgba(206, 0, 0, 0.8);
  background: linear-gradient(0deg, rgb(201, 84, 84) 0%, rgba(255,255,255) 100%);
}